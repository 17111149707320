.templates-page {
  padding: 20px;
}

.template-input textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
}

.template-list {
  margin-top: 20px;
}

.template-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.template-item p {
  flex-grow: 1;
  cursor: pointer;
  padding-right: 10px;
}

.template-item button {
  color: red;
  cursor: pointer;
}
