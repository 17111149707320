body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  .App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  