/* AnnouncementForm.css */

.announcement-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .announcement-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .announcement-form input[type="text"],
  .announcement-form input[type="date"],
  .announcement-form input[type="number"],
  .announcement-form select,
  .announcement-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .announcement-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .announcement-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .announcement-form button:hover {
    background-color: #0056b3;
  }
  
  .image-preview img {
    max-width: 100px;
    margin: 5px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .platform-selection label {
    margin-right: 15px;
    font-weight: normal;
  }
  
  .platform-selection input[type="checkbox"] {
    margin-right: 5px;
  }
  