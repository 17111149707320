/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  /* App Container */
  .App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Headings */
  h1, h2, h3 {
    margin: 20px 0;
    font-weight: 300;
    color: #333;
  }
  
  /* Form Styles */
  .announcement-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .announcement-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .announcement-form input[type="text"],
  .announcement-form input[type="date"],
  .announcement-form input[type="number"],
  .announcement-form select,
  .announcement-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .announcement-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .announcement-form input[type="file"] {
    margin-bottom: 10px;
  }
  
  /* Button Styles */
  .announcement-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .announcement-form button:hover {
    background-color: #0056b3;
  }
  
  .announcement-form .emoji-picker {
    margin-top: 10px;
  }
  
  /* Image Preview Styles */
  .image-preview img {
    max-width: 100px;
    margin: 5px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .image-preview button {
    margin-top: 10px;
    background-color: #dc3545;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .image-preview button:hover {
    background-color: #c82333;
  }
  
  /* Platform Selection Styles */
  .platform-selection {
    margin: 20px 0;
  }
  
  .platform-selection label {
    margin-right: 15px;
    font-weight: normal;
  }
  
  .platform-selection input[type="checkbox"] {
    margin-right: 5px;
  }
  
  /* Dropdown Container */
  .dropdown-container {
    margin-top: 20px;
  }
  
  /* Log Table Styles (shared with MessageLog.css) */
  .log-table th, .log-table td {
    padding: 12px;
    text-align: left;
  }
  
  .log-table th {
    background-color: #007bff;
    color: white;
  }
  
  .log-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  